input[type="number"]::-moz-inner-spin-button,
input[type="number"]::-moz-inner-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.acrMonitorBody {
  height: 90.4%;
  overflow: auto;
  margin: auto;
  display: flex;
  width: 100%;
}
.notificationContainer {
  margin: "auto 0 2em 0";
  bottom: 0;
  position: fixed;
  right: 0;
}
